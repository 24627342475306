import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import "./style.css";
import BNB from "../../images/bnb.png";
import BUSDLOGO from "../../images/busd.webp";
import logo from "../../images/logo.png";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";
import { usePresaleContract, useTokenContract } from "../../hooks/useContract";
import { contracts } from "../../Helpers/contracts";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon
} from "react-share";

const isValid = (regex) => (input) => regex.test(input);
const numberRegex = /^\d*\.?\d*$/;
const isValidNumber = isValid(numberRegex);

const MainSection = () => {
  const [agreeTerms, setAgreeTerms] = useState("true");
  const { active, account, library, connector, activate, diactivate } = useWeb3React();
  const coinValBNB = 42000;
  const coinValBUSD = 100;
  
  const { wallet } = useParams();
  
  const pContract = usePresaleContract(contracts.presale);
  const tContract = useTokenContract(contracts.token);
  const busdContract = useTokenContract(contracts.busd);

  const [uplineInfo, setUplineInfo] = useState({
    upline:"",
    totalBuy:0,
    totalBonus:0,
    indirectBonus:0,
    directBonus:0,
    balance:0
  });
  const [coin, setCoin] = useState("bnb");
  const [coinVal, setCoinVal] = useState(coinValBNB);
  const [approved, setApproved] = useState(false);
  const [enable, setEnable] = useState(true);
  const [referer, setReferer] = useState(contracts.presale);
  const [shareUrl, setShareUrl] = useState("");
  const [data, setData] = useState({
    bnb: "",
    coin: ""
  });



  const processBuy = async () => {
    try {
      if(!data.bnb || !data.coin) {
        alert('Please enter correct value.')
        return;
      }

      if(!agreeTerms) {
        alert("Please agree to our terms and conditions.");
        return;
      }

      setEnable(false);
      
      let totalWeiAmount = await library.utils.toWei(data.bnb, "ether");

      if(coin === "bnb") {
        await pContract.methods.buyTokens(referer).send({from: account, value:totalWeiAmount})
          .on('error', function(error){
            setEnable(true)
            alert(error.message)
          }).then(function(receipt){
            alert(`${data.coin} ${process.env.REACT_APP_TOKEN} has been bought.`);
            setEnable(true);
            setData({bnb:"",coin:""})
          })
        } else if (coin === "busd") {
          console.log('coin', coin)
          await pContract.methods.buyTokenWithToken(totalWeiAmount.toLocaleString('fullwide', {useGrouping:false}), referer)
          .send({from: account})
          .then((receipt)=> {
            alert(`${data.coin} ${process.env.REACT_APP_TOKEN} has been bought.`);
            setEnable(true);
            setData({bnb:"", coin:""});
          })
      }
    } catch (error) {
      setEnable(true)
      console.log(error)
    }
    
  }

  const approveBusd = async () => {
    try {
      setEnable(false);
      let decimals = 18;
      const finalAmount = data.bnb * 10**decimals;
      await busdContract.methods.approve(contracts.presale, finalAmount.toLocaleString('fullwide', {useGrouping:false}))
        .send({from: account})
        .then((receipt) => {
          console.log(receipt);
          setApproved(true);
          setEnable(true);
        })
    } catch (error) {
      setEnable(true);
      console.log('error', error)
    }
  }

  const checkIfApproved = async () => {
    try {
      setEnable(false)
      const decimals = await busdContract.methods.decimals().call();
      const finalAmount = data.bnb * 10**decimals;
      const allowance = await busdContract.methods.allowance(account, contracts.presale).call();
      if(allowance >= finalAmount){
        setApproved(true);
      } else {
        setApproved(false);
      }
      setEnable(true)
    } catch (error) {
      setEnable(true);
      setApproved(false);
    }
  }

  const setCoinVals = () => {
    if(coin == "bnb"){
      setCoinVal(coinValBNB);
    } else {
      setCoinVal(coinValBUSD);
    }
  }

  const buyToken = async () => {
    if(active){
      processBuy();
    } else {
      await activate(Injected);
    }
  }

  const setUplineInfos = async () => {
    try {
      const upline = await pContract.methods.upline(account).call();

      let totalbuy = await pContract.methods.totalBuy(account).call();
      totalbuy = await library.utils.fromWei(totalbuy, "ether");
      
      let totalbonus = await pContract.methods.totalBonus(account).call();
      totalbonus = await library.utils.fromWei(totalbonus, "ether");

      let directbonus = await pContract.methods.level1Bonus(account).call();
      directbonus = await library.utils.fromWei(directbonus, "ether");

      let indirectbonus = await pContract.methods.level2Bonus(account).call();
      indirectbonus = await library.utils.fromWei(indirectbonus, "ether");

      let balance = await tContract.methods.balanceOf(account).call();
      balance = await library.utils.fromWei(balance, "ether");

      let info = {};
      info.upline = upline;
      info.totalBonus = totalbonus;
      info.totalBuy = totalbuy;
      info.directBonus = directbonus;
      info.indirectBonus = indirectbonus;
      info.balance = balance;
      setUplineInfo(info);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setCoinVals();
  }, [coin])

  useEffect(() => {
    checkIfApproved();
  }, [data])

  useEffect(() => {
    if(wallet !== undefined){
      setReferer(wallet);
    }
    setShareUrl(`${window.location.origin}/${account}`);
  }, [wallet, account])

  useEffect(() => {
    setUplineInfos();
  },[account])

  return (
    <div className="main-section">
      <p className="headings">Presale for {process.env.REACT_APP_TOKEN} Begins..</p>
      <p className="headings">Early access to {process.env.REACT_APP_TOKEN} tokens</p>
      <div className="main-section-form">
        <p>1 {coin=="bnb"?"BNB":"BUSD"} = {coinVal.toLocaleString()} {process.env.REACT_APP_TOKEN}</p>
        <p className="mgtp">Pay with</p>
        <div className="form-group">
          <input
            type="text"
            value={data.bnb}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                bnb: val,
                coin: val * coinVal,
              });
            }}
          />
          <div>
            <img src={coin=="bnb"?BNB:BUSDLOGO} alt="snk" style={{ marginRight: "10px" }} />
            <select onChange={(e) => setCoin(e.target.value)} style={{padding:'3px', border:'solid 1px #ccc', borderRadius:'7px', fontWeight:'bolder'}}>
              <option value="bnb" selected={coin=="bnb"?true:false}>BNB</option>
              <option value="busd" selected={coin=="busd"?true:false}>BUSD</option>
            </select>
          </div>
        </div>
        <p className="mgtp">You will get</p>
        <div className="form-group">
          <input
            type="text"
            value={data.coin}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                coin: val,
                bnb: val / coinVal,
              });
            }}
          />
          <div>
            <img src={logo} alt="snk" />
            <span style={{padding:'3px 10px', border:'solid 1px #ccc', backgroundColor:'#ccc', borderRadius:'7px', color:'#000'}}>{process.env.REACT_APP_TOKEN}</span>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <a
            href="www.google.com"
            style={{ color: "#000000" }}
            className="bold"
          >
            
          </a>
        </div>
        <div style={{textAlign:"center"}}>
          <input type="checkbox" checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)} /> <a target="_blank" href="https://squaremeter.pro/terms-of-use/">Agree to Terms and Conditions</a>
          <br/>
          <br/>
          <br/>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          {
            account
            ?
            (
              coin == "bnb"
                ?
                  <button className="buy btn-primary" onClick={buyToken} disabled={!enable}>{account?"Buy":"Connect"}</button>
                :
                  <button className="buy btn-primary" onClick={approved ? buyToken : approveBusd} disabled={!enable}>{account?(approved?"Buy":"Approve BUSD"):"Connect"} </button>
            )
            : <div>Please connect Wallet</div>
          }
          <span className={!enable?"loading":""} style={{marginLeft:'2px', position:'relative', top:'4px'}}></span>
          {/* <a href="#">
            <button className="buy" style={{display:'block', margin:'0 auto'}}>Buy In Pinksale</button>
          </a> */}
        </div>

        <div className="smart">
          <i className="fa fa-lock" aria-hidden="true"></i>
          <p>100% Secure smart contract</p>
        </div>
      </div>
      

      {
        account
        ?
          <div className="main-section-form" style={{marginTop:'15px'}}>
              <h2 style={{textAlign:'center'}}>Upline Info</h2>
              <hr/>
              <div className="upline-info">
                <div className="upline-wallet">Your Upline: {uplineInfo.upline.includes("0x000000")?"No Upline":`${uplineInfo.upline.substring(0, 4)}...${uplineInfo.upline.substring(38)}`}</div>
                <table className="upline-table" border="1" style={{fontSize:'10px'}}>
                  <thead>
                    <tr>
                      <th>Total Buy</th>
                      <th>Total Bonus</th>
                      <th>Direct Referral Bonus</th>
                      <th>Indirect Referral Bonus</th>
                      <th>My Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{parseFloat(uplineInfo.totalBuy).toLocaleString()} SQM</td>
                      <td>{parseFloat(uplineInfo.totalBonus).toLocaleString()} SQM</td>
                      <td>{parseFloat(uplineInfo.directBonus).toLocaleString()} SQM</td>
                      <td>{parseFloat(uplineInfo.indirectBonus).toLocaleString()} SQM</td>
                      <td>{parseFloat(uplineInfo.balance).toLocaleString()} SQM</td>
                    </tr>
                  </tbody>
                </table>
                <div style={{padding:'5px 0px', fontSize:'12px'}}>Your referer URL: <a href={shareUrl}>{shareUrl}</a></div>
                <div style={{padding:'5px 0px', fontSize:'12px'}}>
                  <FacebookShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <FacebookIcon size={32} round={true}/>  
                  </FacebookShareButton>

                   <EmailShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <EmailIcon size={32} round={true}/>  
                  </EmailShareButton>  

                  <TwitterShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <TwitterIcon size={32} round={true}/>  
                  </TwitterShareButton>

                  <TumblrShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <TumblrIcon size={32} round={true}/>  
                  </TumblrShareButton>

                  <TelegramShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <TelegramIcon size={32} round={true}/>  
                  </TelegramShareButton>

                  <ViberShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <ViberIcon size={32} round={true}/>  
                  </ViberShareButton>

                  <WhatsappShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <WhatsappIcon size={32} round={true}/>  
                  </WhatsappShareButton>

                  <RedditShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <RedditIcon size={32} round={true}/>  
                  </RedditShareButton>

                  <LinkedinShareButton url={shareUrl} style={{marginLeft:'2px', marginRight:'2px'}} >
                    <LinkedinIcon size={32} round={true}/>  
                  </LinkedinShareButton>
                </div>
              </div>
          </div>
        :''

      }


      <div className="link">
        <a href="https://bscscan.com/address/0x71523919Deb2E090851657b631607e525d04a5D9">{process.env.REACT_APP_TOKEN} Token contract</a>
      </div>
      <div className="link">
        <a href="https://bscscan.com/address/0x68a82F9324b780C0f3d66052C16352CA902A8959">{process.env.REACT_APP_TOKEN} presale smart contract</a>
      </div>
      <div className="link">
        {/* <a href="www.google.com">How to add {process.env.REACT_APP_TOKEN} token to your wallet</a> */}
      </div>

    </div>
  );
};

export default MainSection;
