import { useEffect } from "react";
import React from "react";
import Logo from "../../images/logo.png";
import "./style.css";
import mLogo from "../../images/metamask.svg";
import wLogo from "../../images/wconnect.svg";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const walletconnect = new WalletConnectConnector({
  rpc:{
      56:"https://bsc-dataseed.binance.org/",
      97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
      1:"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      5:"https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
  }
});

const Header = () => {
  
  const { active, account, library, connector, activate, deactivate, chainId} = useWeb3React();

  const connect = async (type) => {
   try {
      if(type === 'metamask'){
          activate(Injected);
        } else {
          await activate(walletconnect, undefined, true).catch((err) => {
            walletconnect.walletConnectProvider = undefined;
          });
      }
   } catch (error) {
     console.log('error', error)
   }
  }

  useEffect(() => {
    if(account != undefined && chainId !== 56) {
      alert('Wrong Network! Connect BSC Network.');
      deactivate();
    }
  },[account])

  return (
    <div>
      <div className="header">
        <img src={Logo} alt="logo" />
        <div>
            <a href="#" style={{textDecoration:'none'}} onClick={account?deactivate:() => connect('metamask')}>
              <button>{account?`${account.substring(0,4)}...${account.substring(38)}`:'Connect'}</button>
            </a>
        </div>
      </div>

    {
      !account
      ?
        <div id="open-modal" className="modal-window">
          <div>
            <a href="#" title="Close" className="modal-close">Close</a>
            <div className="wallet-logo" style={{textAlign:'center', padding:'21px', cursor:'pointer'}} onClick={()=> connect('metamask')}>
              <img style={{width:'100px'}}src={mLogo}/><br/>
              <h3>Metamask</h3>
            </div>
            <hr/>
            <div className="wallet-logo" style={{textAlign:'center', padding:'21px', cursor:'pointer'}} onClick={()=> connect('wconnect')}>
              <img style={{width:'100px'}}src={wLogo}/><br/>
              <h3>Wallet Connect</h3>
            </div>
          </div>
        </div>
        :''

    }
    </div>
  );
};



export default Header;
