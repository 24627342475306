import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import IERC20 from "../Helpers/abis/IERC20.json";
import SQMPresale from "../Helpers/abis/SQMPresale.json";


function useContract(address, abi, withSignerIfPossible = true) {
    const { library, account } = useWeb3React();
  
    return useMemo(() => {
      if (!address || !abi || !library) return null;
      try {     
        return new library.eth.Contract(abi, address);
      } catch (error) {
        return null;
      }
    }, [address, abi, library, withSignerIfPossible, account]);
}


export function useTokenContract(address){
    return useContract(address, IERC20.abi, true);
}

export function usePresaleContract(address){
    return useContract(address, SQMPresale.abi, true);
}

