import React from 'react';
import Routes from './utils/Routes';
import './App.css';

const App = () => {
  return (
    <>
      <Routes/>
    </>
  );
}

export default App;
