import { useRoutes, BrowserRouter } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MainSection from '../components/main/MainSection';

const Router = () => {
    return useRoutes([
        {path:'/:wallet', element: <MainSection/> },
        {path:'/', element: <MainSection/> },
    ])
}

const Routes = () => {
    return (
        <BrowserRouter>
            <Header/>
            <Router/>
            <Footer/>
        </BrowserRouter>
    )
}

export default Routes;